import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {Form, Field, CellGroup, Button, Cell, Popup, Picker} from 'vant'

createApp(App)
.use(Form)
.use(Field)
.use(CellGroup)
.use(Popup)
.use(Cell)
.use(Button)
.use(Picker)
.use(store)
.use(router)
.mount('#app')
