import { createRouter, createWebHistory } from 'vue-router'
import { Notify } from 'vant'
const cg = () => import('@/views/cg')
const xs = () => import('@/views/xs')
const login = () => import('@/views/login')
const admin = () => import('@/views/admin')
import store from "../store";

const routes = [
  {
    path: '',
    redirect: '/cg'
  },
  {
    path: '/cg',
    name: 'cg',
    component: cg,
    meta: {
      title: '价格换算'
    }
  },
  {
    path: '/xs',
    name: 'xs',
    component: xs,
    meta: {
      title: '价格换算'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      title: '登录'
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: admin,
    meta: {
      title: '管理',
      isAuthRequired: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.isAuthRequired && store.state.user.isLogin === false) {
    Notify('您还没有登录，请先登录')
    return next({ path: '/login' });
  } else {
    next();
  }
  document.title = to.meta.title;
})

export default router
