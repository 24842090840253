<template>
  <nav>
    <!-- <div  class="js"> -->
      <router-link to="/cg">采购&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</router-link>
    <router-link to="/xs">销售</router-link>
    <!-- </div> -->
    <router-link to="/admin" class="admin">管理</router-link>
  </nav>
  <router-view />
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #253d55;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }

  // .js{
  //   display: flex;
  //   justify-content: space-around;
  // }
  .admin {
    float: right;
  }
}
</style>
